import './WhatsappButton.css'

const WhatsappButton = () => {
    return (
        <div className='fixedContainer'>
            <a className='contactFixed' href='#contact' rel='noreferrer'>
                Contacto
            </a>
            <a className='whatsappButton' href='https://wa.me/+541133152112?text=¡Hola! Mi número de chasis es...' target='_blank' rel='noreferrer' >
                <img src='media/whatsapp.png' alt='whatsapp' title='Contactar por Whatsapp' />
            </a>
        </div>
    )
}

export default WhatsappButton
