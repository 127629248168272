import './Carousel.css'
import '@splidejs/react-splide/css'
import { Splide, SplideSlide } from '@splidejs/react-splide'

const Carousel = () => {
    return (
        <div className='carouselContainer'>
            <Splide options={{type: 'slide', rewind: true, gap: '1rem', rewindSpeed: '1500', interval: '6000', speed: '2000', arrows: false, autoplay: true}}>
                <SplideSlide>
                    <img src='./media/utilitarios.png' alt='utilitarios' />
                </SplideSlide>
                <SplideSlide>
                    <img src='./media/omnibus.png' alt='omnibus' />
                </SplideSlide>
                <SplideSlide>
                    <img src='./media/camiones.png' alt='carousel3' />
                </SplideSlide>
            </Splide>
        </div>
    )
}

export default Carousel