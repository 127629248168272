import './Reviews.css'
import { useEffect } from 'react'

const Reviews = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://apps.elfsight.com/p/platform.js"
        script.async = true
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div className="elfsight-app-ad24b44a-6cfd-4339-9c7c-e3e2c6940455"></div>
    )
}

export default Reviews