import './Section.css'

const Section = ({ id, title, description, link, linkText }) => {
    return (
        <div className='sectionContainer' id={id}>
            <h2>{title}</h2>
            <div className='sectionText'>
                {description}
            </div>
            {link ? <a className='sectionAnchor' href={link}>{linkText}</a> : null}
        </div>
    )
}

export default Section