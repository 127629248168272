import './Navbar.css'

const Navbar = () => {
    return (
        <div className='navbarBack'>
            <img className='headerBackground' src='media/header-bg.png' alt='fondo' />
            <div className='backgroundSphere'></div>
            <div className='indexMenu'>
                <div className='flexIndex'>
                    <img src='media/logo.png' alt='logo' />
                    <h2>DIESEL AVENIDA</h2>
                </div>
                <div className='navbarContainer'>
                    <nav className='stroke'>
                        <ul className='flexNavigation'>
                            <li><a href='/#'>INICIO</a></li>
                            <li><a href='/#products'>PRODUCTOS</a></li>
                            <li><a href='/#us'>NOSOTROS</a></li>
                            <li>|</li>
                            <li><a href='/#contact'>CONTACTO</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className='hamMenu'>
                <input type='checkbox' id='hamMenu' />
                <label htmlFor='hamMenu'>
                    <img src='media/menu.svg' alt='menu' />
                </label>
                <ul className='flexHam'>
                    <div className='triangle'></div>
                    <li><a href='/#'>INICIO</a></li>
                    <li><a href='/#products'>PRODUCTOS</a></li>
                    <li><a href='/#us'>NOSOTROS</a></li>
                    <li><a href='/#contact'>CONTACTO</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar