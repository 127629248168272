/* --- IMPORT STYLING --- */
import './index.css'
import './fonts/roboto_condensed/RobotoCondensed-Light.ttf'
import './fonts/roboto_condensed/RobotoCondensed-Regular.ttf'
import './fonts/roboto_condensed/RobotoCondensed-Bold.ttf'
/* --- IMPORT COMPONENTS --- */
import Navbar from '../src/components/Navbar/Navbar'
import Carousel from './components/Carousel/Carousel'
import Section from './components/Section/Section'
import Reviews from './components/Reviews/Reviews'
import Contact from './components/Contact/Contact'
import Faq from './components/Faq/Faq'
import Footer from './components/Footer/Footer'
import WhatsappButton from './components/WhatsappButton/WhatsappButton'

const App = () => {
  return (
    <div>
      <Navbar />
      <Carousel />
      <div className='bodyContent'>
        <img className='logoTitle' src='media/logo.png' alt='logo' />
        <h2 className='dieselTitle'>¡Bienvenido a Diesel Avenida!</h2>
        <p className='dieselDescription'>Puede encontrar información sobre nosotros a continuación...</p>
        <div className='sectionContent'>
          <Section id='presentation' title='VENTA DE REPUESTOS' description='Venta de repuestos Mercedes Benz originales nacionales e importados para buses, utilitarios y camiones' link='' linkText='' />
          <Section id='products' title='PRODUCTOS' description='Línea completa procurando proveer los mejores productos disponibles del mercado para motor, caja, diferencial, tren delantero, freno, carrocería, y todo lo que nuestros clientes puedan necesitar' link='' linkText='' />
          <Section id='us' title='NOSOTROS' description='Contamos con el personal y la estructura necesaria para intentar satisfacer todas las necesidades de nuestros clientes. No dude en contactarnos ' link='#contact' linkText='Contacto' />
        </div>
        <Reviews />
        <Faq />
      </div>
      <Contact />
      <Footer />
      <WhatsappButton />
    </div>
  )
}

export default App
